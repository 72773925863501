.AdminLayout {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background: #f1f0ff;
  grid-template-areas:
    "header"
    "main"
    "footer";
  &__header {
    grid-area: header;
    position: sticky;
    z-index: 2;
    top: 8px;
    left: 8px;
    right: 8px;
  }
  &__main {
    grid-area: main;
    padding: 24px;
    max-width: 1536px;
    width: 100%;
    justify-self: center;
    box-sizing: border-box;
  }
  &__footer {
    grid-area: footer;
  }
}
