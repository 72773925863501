.USForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  &__title {
    font-size: 34px !important;
    font-weight: 400 !important;
    span {
      color: #fac900;
    }
  }
  .subtitle {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  &__requirement {
    font-size: 14px;
    font-weight: 300;
  }
  &__step {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    span {
      color: #fac900;
    }
    &__number {
      background: white;
      border-radius: 6px;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6854ff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.168);
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.084);
    }
    &__text {
      flex: 1;
    }
  }
  &__file {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white !important;
    gap: 24px;
    margin-bottom: 16px;
  }
  &__image-preview {
    padding: 40px;
    text-align: center;
    max-width: 360px;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__validation {
    position: absolute;
    width: calc(100% - 80px);
    min-height: calc(100% - 80px);
    left: 40px;
    top: 40px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 12px;
    box-sizing: border-box;
    &__title {
      text-align: center;
      font-size: 20px;
      margin-bottom: 16px;
    }
    &__text {
      text-align: center;
      font-size: 14px;
      margin-bottom: 24px;
      opacity: 0.8;
    }
    &__item {
      display: flex;
      gap: 12px;
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
    }
  }
  &__terms {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    font-weight: 300;
    a {
      color: white;
    }
  }
  &__back {
    a {
      margin-bottom: 24px;
      margin-top: -12px;
      display: flex;
      gap: 12px;
      font-size: 12px;
      font-weight: 300;
      align-items: center;
      text-decoration: underline;
      position: absolute;
      top: 48px;
      right: 24px;
      color: white;
      opacity: 0.7;
    }
  }
}
