.Home {
  color: white;
  &__banner {
    background-image: url("./images/banner.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 32px;
    width: 100%;
    text-align: center;
    height: 507px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 24px;
    padding: 16px;
    box-sizing: border-box;
    &__title {
      font-weight: 500;
      font-size: 48px;
      display: flex;
      gap: 24px;
      align-items: center;
      @media screen and (max-width: 780px) {
        flex-direction: column;
        gap: 14px;
      }
      &__logo {
        background: white;
        padding: 28px;
        border-radius: 24px;
        display: flex;
        img {
          width: 306px;
        }
        @media screen and (max-width: 780px) {
          padding: 20px;
          img {
            width: 222px;
          }
        }
      }
    }
    &__subtitle {
      font-size: 24px;
      font-weight: 400;
      @media screen and (max-width: 780px) {
        font-size: 20px;
      }
    }
  }
  &__section1 {
    display: flex;
    gap: 24px;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }
  &__card {
    border-radius: 32px;
    overflow: hidden;
    &--1 {
      flex: 2;
      background: #6854ff;
      display: flex;
      @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        flex: 1;
      }
      &__image {
        background-image: url("./images/card1.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        width: 286px;
        @media screen and (max-width: 1000px) {
          height: 144px;
          width: 100%;
        }
      }
      &__title {
        flex: 1;
        padding: 84px 72px;
        font-size: 34px;
        font-weight: 400;
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
          padding: 48px 24px;
          font-size: 24px;
        }
        p {
          margin: 0;
          &:first-child {
            margin-bottom: 24px;
          }
        }
      }
    }
    &--2 {
      flex: 1;
      background-image: url("./images/card2.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      color: white;
      text-decoration: none;
      display: block;
      @media screen and (max-width: 1000px) {
        min-height: 443px;
      }
      &__yellow {
        background: #fac900;
        border-radius: 32px;
        padding: 32px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 24px;
        height: 230px;
        display: flex;
        align-items: end;
        position: relative;
      }
      &__plus {
        position: absolute;
        top: 24px;
        right: 24px;
      }
    }
  }
}
