.MuiFormControl-root {
  width: 100%;
  margin-bottom: 16px !important;
}

.MuiInputBase-root {
  background: #6154c9;
  border-radius: 10px;
  color: white !important;
  height: 60px;
  input::placeholder {
    opacity: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid white !important;
    border-radius: 10px;
  }
}
