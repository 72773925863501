.AuthModal {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  .subtitle,
  .title {
    margin-bottom: 24px;
  }
  &__email {
    margin-top: 24px;
  }
  &__change-view {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 16px;
    text-align: center;
  }
  .Checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .MuiFormControlLabel-label {
      font-size: 12px !important;
      a {
        color: white;
        margin-bottom: 2px;
      }
    }
  }
}
