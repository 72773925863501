.Layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #6854ff;
  &__logo {
    position: absolute;
    left: 24px;
    top: 36px;
    width: 80px;
    img {
      width: 100%;
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 24px;
    padding-top: 96px;
    box-sizing: border-box;
    max-width: 600px;
    height: 100%;
  }
}
