.IgModalAccount {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.8);
  }
  .MuiDialog-paper {
    background: #6854ff;
    color: white;
    font-family: "DM Sans";
    padding: 16px 8px;
  }
  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 90%;
    padding-bottom: 16px;
  }

  &__title {
    margin-bottom: 16px;
    span {
      color: #fac900;
    }
  }
  &__subtitle {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.7;
  }

  .MuiDialogContent-root {
    padding: 20px 16px 8px 16px;
    p {
      color: white;
      font-family: "DM Sans";
      padding-bottom: 16px;
      line-height: normal;
    }
  }

  .MuiDialogActions-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  button {
    background: white !important;
    width: 100%;
    color: black !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 16px !important;
    height: 60px !important;
    border-radius: 10px;
    border: none;
    &:disabled {
      opacity: 0.5;
    }
    img {
      width: 32px;
    }
  }
}
