.IgModal {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.8);
  }
  .MuiDialog-paper {
    background: #6854ff;
    color: white;
    font-family: "DM Sans";
    padding: 24px 8px;
  }
  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    padding-bottom: 24px;
  }
  .MuiDialogContent-root p {
    color: white;
    font-family: "DM Sans";
    padding-bottom: 24px;
    line-height: normal;
    font-weight: 200;
  }
  &__subheader {
    font-weight: 400 !important;
  }

  button {
    background: white !important;
    width: 100%;
    color: black !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 16px !important;
    height: 60px !important;
    border-radius: 10px;
    border: none;
    img {
      width: 32px;
    }
  }
}
