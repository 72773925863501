.USQR {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  .title {
    margin-bottom: 24px;
    color: #fac900;
  }
  &__text {
    margin-bottom: 36px;
    b {
      color: #fac900;
    }
  }
  &__qr-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
  }
  &__qr {
    background: white;
    padding: 8px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    max-width: 288px;
  }
  &__delivery {
    &__title {
      font-size: 24px;
      color: #fac900;
      margin-bottom: 16px;
    }
    &__text {
      font-size: 14px;
      opacity: 0.8;
      margin-bottom: 12px;
    }
  }
}
