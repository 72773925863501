.Countdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border: 4px solid white;
  border-radius: 18px;
  color: white;
  max-width: 328px;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid white;
    padding: 28px 0;
    width: 80px;
    &:last-child {
      border: none;
    }
  }
  &__value {
    font-size: 48px;
    font-weight: 600;
  }
  &__label {
    font-size: 12px;
    text-transform: capitalize;
  }
}
