.Loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
  &__title {
    color: white;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 12px;
  }
  &__text {
    color: white;
    margin-bottom: 16px;
    opacity: 0.7;
  }
}
