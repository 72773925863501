.Header {
  background: white;
  height: 64px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 16px);
  margin: auto;
  &__logo {
    img {
      display: block;
    }
  }
}
