.USThanks {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #59ba95;
  overflow-y: auto;

  &__layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 24px;
    padding-top: 60px;
    box-sizing: border-box;
    max-width: 600px;
    height: 100%;
  }

  &__thanks-img {
    width: 100%;
    text-align: right;
    margin-bottom: 64px;
  }

  .title {
    margin-bottom: 24px;

    &:first-child {
      margin-bottom: 40px;
    }
  }

  &__content {
    margin-bottom: 100px;
  }

  &__referral {
    margin-bottom: 24px;

    &__title {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
      color: #FFE554;
    }

    &__subtitle {
      margin-bottom: 16px;
      opacity: 0.8;
    }

  }

  &__button {
    margin-bottom: 24px;
  }
}