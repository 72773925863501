.Footer {
  background-color: #6854ff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 20px;
  width: calc(100vw - 16px);
  margin: auto;
  margin-bottom: 8px;
  font-size: 14px;
}
