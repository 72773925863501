.USError {
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ff6854;
  &__layout {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-height: 100vh;
    padding: 24px;
    padding-top: 60px;
    box-sizing: border-box;
    max-width: 600px;
    height: 100%;
  }
  &__thanks-img {
    width: 100%;
    text-align: right;
    margin-bottom: 64px;
  }
  .title {
    margin-bottom: 24px;
  }
}
