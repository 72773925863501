.USLanding {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__image {
    width: 120px;
    margin-bottom: 36px;
    margin-left: 40px;
    img {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 24px;
  }
  &__text {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 32px;
  }
  &__prize {
    background: #fac900;
    color: black;
    border-radius: 10px;
    height: 60px;
    width: 100%;
    border: none;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 24px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    & > div {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  &__terms {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
    margin-bottom: 12px;
    a {
      color: white;
    }
  }
}
