.Autocomplete {
  width: 100% !important;

  .MuiInputBase-root {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #fce27e;
    background: #fff;
  }

  input {
    border: none !important;
    height: auto !important;
    color: #000 !important;
    padding: 0 !important;
  }

  fieldset {
    border-radius: 32px;
    border: 1px solid #fce27e;
  }
}