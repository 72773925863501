@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

$blue: #6854ff;
$yellow: #fac900;

body {
  font-family: "DM Sans";
  margin: 0;
  font-size: 16px;
  color: white;
}

.title {
  font-weight: 400;
  font-size: 34px;
  line-height: 100%;
  span {
    color: #fac900;
  }
}

.subtitle {
  font-weight: 500;
  opacity: 60%;
}

.button {
  background: $yellow;
  color: black;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 500;
  &.secondary {
    background: transparent;
    border: 2px solid white;
    color: white;
  }
  &.disabled {
    opacity: 50%;
  }
}
