.USAlreadyParticipating {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 60px;
  justify-content: space-between;
  align-items: center;
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    margin-bottom: 24px;
  }
  .Countdown {
    margin-top: 36px;
  }
  &__text {
    color: #b2a8fb;
  }
}
